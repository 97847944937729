<template>
  <div id="profile-confirm">
    <b-btn
      v-if="type === 'PHONE_RESET'"
      aria-label="back"
      variant="link"
      class="back-button"
      @click="clickBack"
    >
      <i class="material-icons">arrow_back</i>
    </b-btn>
    <h1 class="profile-title">
      Success!
    </h1>
    <p class="profile-description">
      {{ description }}
    </p>
    <b-btn
      v-if="type === 'PHONE_RESET'"
      aria-label="click to go back to profile"
      variant="link"
      @click="clickBack"
    >
      Go back to profile
    </b-btn>
  </div>
</template>

<script>
export default {
  name: 'UserProfileConfirm',
  data() {
    return {
      type: this.$route.query.type,
    };
  },
  computed: {
    description() {
      switch (this.type) {
        case 'EMAIL_RESET':
          return 'Your email address has been verified. You\'ll now receive updates about free access offers and new promotions.';
        case 'PHONE_RESET':
          return 'Your mobile number has been verified. Now you can use it to log in to the Aira app.';
        default:
          return 'How did you get in here?!';
      }
    },
  },
  methods: {
    clickBack() {
      this.$store.dispatch('getUserProfile');
      this.$router.push(`/${this.$route.fullPath.split('/')[1]}/profile`);
    },
  },
};
</script>

<style lang="scss">
#profile-confirm {
  padding: 15px;
  .back-button {
    position: absolute;
    top: 30px;
    left: 15px;
    display: inline;
    color: white;
  }
}
</style>
